@use '../core/variables' as *;

@mixin mobile-to-tablet {
  @media (min-width: 600px) and (max-width: 1000px) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: $breakpoint-tablet - 1) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tablet-large-up {
  @media (min-width: $breakpoint-tablet-large) {
    @content;
  }
}

@mixin tablet-large-down {
  @media (max-width: $breakpoint-tablet-large) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-hd - 1) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-large-up {
  @media (min-width: $breakpoint-desktop-large) {
    @content;
  }
}

@mixin desktop-large-only {
  @media (min-width: $breakpoint-desktop-large) and (max-width: $breakpoint-hd - 1) {
    @content;
  }
}

@mixin desktop-large-down {
  @media (max-width: $breakpoint-desktop-large) {
    @content;
  }
}

@mixin hd-up {
  @media (min-width: $breakpoint-hd) {
    @content;
  }
}

@mixin max-width-up {
  @media (min-width: $container-max-width) {
    @content;
  }
}
